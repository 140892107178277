const firebaseConfig = {
    apiKey: "AIzaSyA4X7bweFZSejzKMYhzzN5_o7TCfj7GcgY",
    authDomain: "dashboard-stats-ca922.firebaseapp.com",
    projectId: "dashboard-stats-ca922",
    storageBucket: "dashboard-stats-ca922.appspot.com",
    messagingSenderId: "1005399955557",
    appId: "1:1005399955557:web:52f41dc6d90d520e4b0e8c",
    measurementId: "G-CYQQ3W6M9B"
};

export { firebaseConfig };