import { useContext, useEffect, useRef, useState } from "react";
import LoginView from "./views/LoginView";
import { AppContext } from './context/app-context';
import Loader from "./components/Loader";
import { UserContext } from "./context/user-context";
import DashboardPage from "./views/DashboardPage";
import { Button } from "@mui/material";

const App = () => {

    const appContext = useContext(AppContext);
    const user = useContext(UserContext);

    return (
        appContext.initialized ?
            appContext.loading ? <Loader /> :
                user.user === null ? <LoginView /> :
                    appContext.listApplication.length > 0 ? <DashboardPage /> : <div className="empty" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'row' }}><h1>Empty data for your account!</h1> <Button variant="contained" onClick={() => { user.signOut() }}>Logout</Button></div>
            : <Loader />
    )


}

export default App;
