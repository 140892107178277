import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import MultiLineGraph from '../components/MultiLineGraph';
import TopBar from "./../components/Topbar";
import Loader from '../components/Loader';
import { Container } from '@mui/system';
import { AppContext } from '../context/app-context';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 35
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
}));

const DashboardPage = () => {

    const classes = useStyles();
    const [impressions, setImpressions] = useState(null);
    const [cpmImpressions, setCpmImpression] = useState(null);
    const app = useContext(AppContext);
    const [monthText, setMonthText] = useState("");
    const currentMonth = useRef(null);
    const currentYear = useRef(null);
    const [noData, setNoData] = useState(false);
    const [titleImp, setTitleImp] = useState("Total Request");
    const [titleCpm, setTitleCpm] = useState("Total CPM");

    useEffect(() => {
        changeApplicationData(app.currentApplication);
    }, [app.currentApplication])

    const modifyMonthNumber = (diff = false) => {
        setImpressions(null);
        setTimeout(() => {
            if (!diff) {
                if (currentMonth.current === 11) {
                    currentYear.current++;
                    currentMonth.current = 0;
                } else {
                    currentMonth.current++;
                }
            } else {
                if (currentMonth.current === 0) {
                    currentYear.current--;
                    currentMonth.current = 11;
                } else {
                    currentMonth.current--;
                }
            }
            changeApplicationData(app.currentApplication);
        }, 500)
    }

    const changeApplicationData = (appname) => {
        const baseDate = moment();
        const _m = baseDate.month();
        const _y = baseDate.year();
        if (currentMonth.current === null) {
            currentMonth.current = _m;
        }
        if (currentYear.current === null) {
            currentYear.current = _y;
        }
        baseDate.set({
            year: currentYear.current,
            month: currentMonth.current,
            date: 1
        }) //.date(1).month(currentMonth.current).year(currentYear.current).format("YYYY-MM-DD");

        const startOfMonth = moment(baseDate).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(baseDate).endOf('month').format('YYYY-MM-DD');
        setMonthText(moment(baseDate).format("MMMM"));
        console.log("DATES", startOfMonth, endOfMonth);
        try {
            fetch("https://36uwyfmq39.execute-api.eu-west-1.amazonaws.com/prod/?appId=" + appname + "&from=" + startOfMonth + " 00:00:00&to=" + endOfMonth + " 23:59:59", {
                method: "GET",
                headers: {
                    'x-api-key': 'YKAND2GFQGDIv29Ax9RchvxhpIwrHZqe7FomBg8t84QTQMY70fESbeElkKcske9kEqyxfWW727TaM2PknSDfDvE1OiObqHSiovZ0fgyagGtQEYB3dur33nxFeu8YmHB0'
                }
            })
                .then(res => res.json())
                .then((data) => {
                    console.log("DATA KAMP", data, app.currentGroup);
                    const result = data.results;
                    const datasImp = [];
                    const datasCPM = [];
                    let totalImp = 0;
                    let totalCPM = 0;

                    // vendibile
                    const dImp = {};
                    dImp.datasets = [];
                    dImp.labels = [];

                    // CPM vendibile
                    const dCPM = {};
                    dCPM.datasets = [];
                    dCPM.labels = [];

                    result.forEach(el => {
                        const day = (moment(el.day).date()).toString();
                        dImp.labels.push(day);
                        dCPM.labels.push(day);

                        const elementParsed = (el.count / 2.50)
                        datasImp.push(elementParsed.toFixed());
                        totalImp = totalImp + elementParsed;

                        const cpm = parseInt(elementParsed / 1000);
                        datasCPM.push(cpm);
                        totalCPM = totalCPM + cpm;
                    })
                    const permissions = app.getPermission(app.currentGroup.current);
                    if (permissions.indexOf("salable") !== -1) {
                        dImp.datasets.push({ data: datasImp, label: appname, backgroundColor: colors.pink[500] });
                        setTitleImp("Total Request: " + parseInt(totalImp.toFixed()).toLocaleString());
                        setImpressions(dImp);
                        console.log("REQUESTS", dImp);
                    }
                    if (permissions.indexOf("cpm-salable") !== -1) {
                        dCPM.datasets.push({ data: datasCPM, label: appname, backgroundColor: colors.blue[500] });
                        setTitleCpm("Total CPM: " + parseInt(totalCPM.toFixed()).toLocaleString());
                        setCpmImpression(dCPM);
                        console.log("CPM", dCPM);
                    }
                }).catch((err) => {
                    setNoData(true);
                })
        } catch (err) {
            console.log("ERROR ON LOAD DATA", err);
        }
    }

    return (
        <div className={classes.root}>
            <TopBar changeApplication={() => { setImpressions(null); setNoData(false); }} />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Container>
                            <Grid container spacing={3} justifyContent="flex-start">
                                <Grid item sm={12}>
                                    {impressions !== null || cpmImpressions !== null ?
                                        <>
                                            {impressions !== null ?
                                                <MultiLineGraph
                                                    title={titleImp}
                                                    dataset={impressions}
                                                    action={(
                                                        <Box display="flex" alignItems="center">
                                                            <IconButton
                                                                onClick={() => {
                                                                    modifyMonthNumber(true);
                                                                }}
                                                                size="large">
                                                                <NavigateBefore />
                                                            </IconButton>
                                                            <Typography>{monthText + " " + currentYear.current}</Typography>
                                                            <IconButton
                                                                onClick={() => {
                                                                    modifyMonthNumber(false);
                                                                }}
                                                                size="large">
                                                                <NavigateNext />
                                                            </IconButton>
                                                        </Box>
                                                    )}
                                                />
                                                : null}
                                            {cpmImpressions !== null ?
                                                <MultiLineGraph
                                                    title={titleCpm}
                                                    dataset={cpmImpressions}
                                                    action={(
                                                        <Box display="flex" alignItems="center">
                                                            <IconButton
                                                                onClick={() => {
                                                                    modifyMonthNumber(true);
                                                                }}
                                                                size="large">
                                                                <NavigateBefore />
                                                            </IconButton>
                                                            <Typography>{monthText + " " + currentYear.current}</Typography>
                                                            <IconButton
                                                                onClick={() => {
                                                                    modifyMonthNumber(false);
                                                                }}
                                                                size="large">
                                                                <NavigateNext />
                                                            </IconButton>
                                                        </Box>
                                                    )}
                                                />
                                                : null}
                                        </>
                                        : !noData ? <Loader /> : <h1 style={{ textAlign: 'center' }}>No Data for this application. Select another from the list!</h1>}
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DashboardPage;