import { Box, CircularProgress } from "@mui/material"

const Loader = (props) => {

    return (
        <Box sx={{ display: 'flex', width: "100%", height: '96vh', alignItems: 'center', justifyContent: 'center', position: 'absolute', zIndex: 9999, backgroundColor: 'rgb(22, 28, 36)', left: '0px', top: '0px' }}>
            <CircularProgress />
        </Box>
    )
}

export default Loader;