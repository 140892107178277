
import React from 'react';

const Logo = (props) => {
    return (
        <img
            alt="KARP Logo"
            title="KARP Logo"
            width="50"
            src="static/logo.svg"
            {...props}
        />
    );
};

export default Logo;
