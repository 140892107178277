import { AppBar, Toolbar, Box, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { height } from '@mui/system';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/app-context';
import { UserContext } from '../context/user-context';
import Logo from './Logo';

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'space-between',
    justifyContent: 'center'
  },
  title: {
    marginLeft: 10,
    fontSize: 16
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  projectsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const TopBar = ({ className, changeApplication, ...rest }) => {

  const user = useContext(UserContext);
  const app = useContext(AppContext);
  const [listApplications, setListApplications] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    let list = [<MenuItem value="NULL" selected={app.currentApplication === "NULL"}>Select Application</MenuItem>];
    app.listApplication.forEach((item) => {
      list.push(<MenuItem value={item.alias} selected={item.alias === app.currentApplication}>{item.name}</MenuItem>)
    })
    setListApplications(list)
  }, [app.listApplication])

  const handleChange = (evt) => {
    changeApplication();
    app.changeApplication(evt.target.value);
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Box item className={classes.logoBox}>
          <Logo />
          <h1 className={classes.title}>Dashboard Stats</h1>
        </Box>
        <Box item className={classes.projectsBox}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Application</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={app.currentApplication}
              label="Select Application"
              onChange={handleChange}
            >
              {listApplications}
            </Select>
          </FormControl>
          <Button
            style={{ marginLeft: 30 }}
            variant='text'
            onClick={() => {
              user.signOut();
            }}>
            LogOut
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
