import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AppProvider from './context/app-context';
import UserProvider from './context/user-context';
import ThemeConfig from './theme'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeConfig>
      <AppProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </AppProvider>
    </ThemeConfig>
  </React.StrictMode>
);
