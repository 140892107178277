import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from './app-context';

const UserContext = React.createContext();

const UserProvider = (props) => {

    const appContext = useContext(AppContext);
    const userFirstLoaded = useRef(false);
    const [auth, changeAuth] = useState(null);
    const [user, setUser] = useState(null);

    const callbackStatus = useRef(null);

    const _signOut = () => {
        signOut(auth).then(() => {
            setUser(null);
        })
    }

    const defaultValues = {
        user: user,
        auth: auth,
        signOut: _signOut
    }

    const userStatusHandler = (_user) => {
        if (user === null && _user !== null) {
            appContext.loadApplications(_user.uid)
        } else {
            appContext.toggleLoader(false);
        }
        setUser(_user);
    }

    useEffect(() => {
        if (appContext.initialized) {
            const _auth = getAuth(appContext.app);
            console.log("USER INIT", _auth);
            changeAuth(_auth);
        }
    }, [appContext.initialized])

    useEffect(() => {
        if (defaultValues.auth !== null && callbackStatus.current === null) {
            callbackStatus.current = onAuthStateChanged(defaultValues.auth, userStatusHandler);
        }
    }, [defaultValues.auth])

    return <UserContext.Provider value={defaultValues}>{props.children}</UserContext.Provider>
}

export default UserProvider;

export { UserContext };