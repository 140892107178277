import { Alert, Box, Button, Container, Snackbar, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAuth, onAuthStateChanged, setPersistence, signInWithEmailAndPassword, indexedDBLocalPersistence } from 'firebase/auth';
import * as Yup from 'yup';
import { UserContext } from '../context/user-context';
import { AppContext } from '../context/app-context';
import Loader from '../components/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = (props) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const [snackAlert, setSnackAlert] = React.useState({ open: false, text: '' });

  const submit = async ({ email, password }) => {
    try {
      setLoading(true);
      await setPersistence(user.auth, indexedDBLocalPersistence).then(async () => {
        await signInWithEmailAndPassword(user.auth, email, password)
          .then((userCredential) => {
            setLoading(false);
          })
          .catch((error) => {
            setSnackAlert({ open: true, text: "Utente non trovato!" });
            setLoading(false);
          });
      })
    } catch (error) {
      setSnackAlert({ open: true, text: error.message });
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackAlert({ open: false, text: '' });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 400)
  }, [])

  return (
    <div className={classes.root}>
      {loading ? <Loader /> : null}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackAlert.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          severity="error"
          color="error"
          elevation={6}
          variant="filled"
          onClose={handleCloseSnack}
        >
          {snackAlert.text}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email("indirizzo email non valido").max(255).required("email richiesta"),
              password: Yup.string().max(255).required("password richiesta"),
            })}
            onSubmit={submit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Accedi
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Accedi alla piattaforma con email e password
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Accedi
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </div>
  );
};

export default LoginView;
