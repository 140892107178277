import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import React, { useEffect, useState, useRef } from 'react'
import { firebaseConfig } from '../costants/firebase-config';
import { getRemoteConfig, getAll, activate, fetchConfig } from "firebase/remote-config";

const AppContext = React.createContext();

const AppProvider = (props) => {

    const [loading, setStatusLoading] = useState(true);
    const [initializing, setStatusInit] = useState(false);
    const [listApplication, setListApplication] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [app, setApp] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [currentApplication, setCurrenteApplication] = useState("NULL");
    const permissionsRef = useRef({});
    const currentGroupRef = useRef(null);

    const loadApplications = async (uid) => {
        const remoteConfig = getRemoteConfig(app);
        remoteConfig.settings.minimumFetchIntervalMillis = 7200;
        await fetchConfig(remoteConfig).then(async () => {
            await activate(remoteConfig).then(() => {
                const data = getAll(remoteConfig);
                const group = JSON.parse(data.users_groups.asString());
                const _permissions = JSON.parse(data.group_permission.asString());
                permissionsRef.current = _permissions;
                const projects = JSON.parse(data.groups.asString());
                if (group[uid] !== undefined) {
                    const _group = group[uid];
                    currentGroupRef.current = _group;
                    if (projects[_group] !== undefined) {
                        const pr = projects[_group];
                        if (pr.length > 0) {
                            setListApplication(pr);
                            setCurrenteApplication(pr[0].alias);
                        }
                        setTimeout(() => {
                            setStatusLoading(false);
                        }, 500)
                    } else {
                        setTimeout(() => {
                            setStatusLoading(false);
                        }, 500)
                    }
                } else {
                    setTimeout(() => {
                        setStatusLoading(false);
                    }, 500)
                }
            })
        })
    }

    const getPermission = (userGroup, permission = null) => {
        if (permission !== null) {

        } else {
            return permissionsRef.current[userGroup];
        }
    }

    const defaultValues = {
        app: app,
        analytics: analytics,
        loading: loading,
        currentApplication: currentApplication,
        listApplication: listApplication,
        initialized: initializing,
        currentGroup: currentGroupRef,
        getPermission: getPermission,
        toggleLoader: setStatusLoading,
        loadApplications: loadApplications,
        changeApplication: setCurrenteApplication
    }

    useEffect(() => {
        let _app = initializeApp(firebaseConfig);
        setApp(_app);
    }, [])

    useEffect(() => {
        if (app !== null) {
            let _analytics = getAnalytics(defaultValues.app);
            setAnalytics(_analytics);
            setStatusInit(true);
        }
    }, [app])

    return <AppContext.Provider value={defaultValues}>{props.children}</AppContext.Provider>
}

export default AppProvider;
export { AppContext };